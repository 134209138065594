import React, { useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Box, Button, Input, FormControl, FormLabel, Text } from "@chakra-ui/react";
import axios from "axios";

let { REACT_APP_BACKEND_API, REACT_APP_DEFAULT_INSTANCE } = process.env;

const Login = () => {
    const { group, instance: urlInstance } = useParams();
    const [groupName, setGroupName] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const instance = urlInstance || REACT_APP_DEFAULT_INSTANCE;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (groupName.trim()) {
            try {
                const response = await axios.get(
                    `${REACT_APP_BACKEND_API}/api/fairground/check-group/${groupName}` + (instance ? `/${instance}` : ''),
                        {
                            responseType: "json",
                            cors: false,
                            transformResponse: (data) => {
                                return JSON.parse(data);
                            },
                        }
                );
                if (response.data.exists) {
                    navigate(`/${groupName}/${instance}`);
                } else {
                    setError('Group does not exist');
                }
            } catch (error) {
                setError('An error occurred. Please try again.');
            }

        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh" flexDirection="column">
            <Box mb={4} textAlign="center">
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Velkommen til
                </Text>
                <Text fontSize="xl" mb={4} fontWeight="bold">
                    Teknologisk Tivoli
                </Text>
            </Box>
            <form onSubmit={handleSubmit}>
                <FormControl id="group-name" isRequired>
                    <FormLabel fontWeight="bold">Skriv jeres gruppenr. her:</FormLabel>
                    <Input
                        type="text"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </FormControl>
                {error && <Box color="red.500" mt={2}>{error}</Box>}
                <Button mt={4} variant="primary" mt="20px" type="submit">
                    Send
                </Button>
            </form>
        </Box>
    );
};

export default Login;

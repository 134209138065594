import { Button, Flex, Heading, Spinner, Text } from "@chakra-ui/react";
import { StationDetails } from "./StationDetails";
import {SystemMessage} from "./SystemMessage";

export const StationInformation = ({
  group,
  station,
  goToNextStation,
  getNextStation,
  skipToNextStation,
  isLoading,
  systemStatus
}) => {
  return (
    <Flex
      direction="column"
      background={goToNextStation === true ? "green15" : "green8"}
      w="100%"
      h="100vh"
      alignItems="center"
      pt="20px"
      overflow-y="scroll"
    >
      {isLoading === true ? (
        <>
          <Text fontSize="4xl">Indlæser...</Text>
          <Spinner size="xl" mt="20px" />
        </>
      ) : (
        <>
          <Heading size="md" as="b">
            Gruppe nr.: {group?.name}
          </Heading>
          <StationDetails station={station} goToNextStation={goToNextStation} systemStatus={systemStatus} />
          <SystemMessage systemStatus={systemStatus} stationTypeMessage={station?.info} upload={station?.upload} stationName={station?.name} />
          {goToNextStation === false ? (
            <Flex
              direction="column"
              h="20vh"
              alignItems="center"
              p="20px"
              minH="150px"
            >
              <Text fontSize="lg">
                Klik her, når I ønsker at gå videre.
              </Text>
              <Button
                variant="primary"
                fontSize="2xl"
                mt="10px"
                onClick={getNextStation}
              >
                Ny station
              </Button>
            </Flex>
          ) : (
            <>
              {station.name && (
                <Button
                  variant="primary"
                  fontSize="xl"
                  mt="10px"
                  onClick={skipToNextStation}
                >
Ny station
                </Button>
              )}
            </>
          )}
        </>
      )}
    </Flex>
  );
};

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import {
    Box, Button, Text, Image, Flex, VStack, Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure
} from '@chakra-ui/react';
import axios from 'axios';

let { REACT_APP_BACKEND_API, REACT_APP_DEFAULT_INSTANCE } = process.env;

const UploadPhoto = ({stationName, upload}) => {
    const { group, instance: urlInstance } = useParams();
    const instance = urlInstance || REACT_APP_DEFAULT_INSTANCE;
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const selectedFile = Object.assign(acceptedFiles[0], {
                    preview: URL.createObjectURL(acceptedFiles[0])
                });
                setFile(selectedFile);
                setError('');

                await handleUpload(selectedFile);
            }
        },
        noClick: true,
        noKeyboard: true,
    });

    const handleUpload = async (selectedFile) => {
        setUploading(true);
        setError('');
        setSuccess('');

        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('instance', instance);
        formData.append('group', group);
        formData.append('station', stationName);
        formData.append('upload', upload);

        try {
            const response = await axios.post(
                `${REACT_APP_BACKEND_API}/api/fairground/upload` + (instance ? `/${instance}` : ''), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            setSuccess('Image uploaded successfully!');
            setFile(null);
        } catch (error) {
            setError('An error occurred during upload. Please try again.');
        } finally {
            setUploading(false);
        }
    };

    return (
        <Box>
            <input {...getInputProps()} />
            <Button mt={2} variant="primary" onClick={open}>Choose Image</Button>
            {file && (
                <>
                    <Flex mt={4} justifyContent="center">
                        <Box p={2}>
                            <Image src={file.preview} alt={file.name} boxSize="100px" objectFit="cover" />
                        </Box>
                    </Flex>
                </>
            )}
            {error && <Text color="red.500" mt={2}>{error}</Text>}
            {success && <Text color="green.500" mt={2}>{success}</Text>}
        </Box>
    );
};

export default UploadPhoto;

import { InfoIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

export const ToEarlyWarningModal = ({ isOpen, onSubmit, onCancel }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="xs">
      <ModalOverlay background="rgba(255,255,255, 0.75)">
        <ModalContent background="green15" color="green75">
          <ModalBody h="100vh">
            <Flex
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              m="20px"
            >
              <InfoIcon color="peach100" boxSize={8} m="10px" />
              <Flex direction="column" justifyContent="center">
                <Text as="b" fontSize="2xl">
                  I er hurtige!
                </Text>
                <br />
                <Text as="b" fontSize="2xl">
                  Er I sikre på, at I ønsker en ny station allerede?
                </Text>
              </Flex>{" "}
              <Flex
                direction="column"
                justifyContent="center"
                h="20vh"
                m="20px"
                w="100%"
              >
                <Button variant="primary" onClick={onSubmit} mt="10px" mb="10px" w="100%">
                  Ja tak
                </Button>
                <Button variant="cancel" onClick={onCancel} mt="10px" mb="10px" w="100%">
                  Nej tak
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

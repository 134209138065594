// eslint-disable-next-line import/no-anonymous-default-export
export default {
  global: {
    body: {
      margin: 0,
      fontFamily: "Roboto-regular",
      color: "text",
      backgroundColor: "background",
      overflow: "scroll",
      height: "100vh",
      textAlign: "center",
    },
  },
};

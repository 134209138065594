import { InfoIcon } from "@chakra-ui/icons";
import { Flex, Text, Button } from "@chakra-ui/react";
import UploadPhoto from "./UploadPhoto";

export const SystemMessage = ({ systemStatus, stationTypeMessage, upload, stationName }) => {

    let message = null;

    switch (systemStatus) {
        //Case 1: Game closed, lunch flag raised
        case 1:
            message = <div>Teknologisk Tivoli starter igen kl. 14.15.</div>;
            break;
        //Case 2: Game closed, finish flag raised
        case 2:
            message = <div>Teknologisk Tivoli er slut. Tak for nu! </div>;
            break;
        //Case 5: Game open, lunch flag raised
        case 5:
            message = <div></div>;
            break;
        //Case 6: Game open, finish flag raised
        case 6:
            message = <div>Nu er vi snart ved vejs ende</div>;
            break;

    }

    return (
        (message || stationTypeMessage || upload) &&
            <Flex
                direction="column"
                background="white"
                m="0px"
                p="20px"
                w="80%"
                alignItems="center"
            >
                <InfoIcon color="text" boxSize={8} m="10px" />
                <Text fontSize="md" as="b">
                    {message}
                </Text>
                <br/>

                <Text fontSize="md" textAlign="center">
                    <div dangerouslySetInnerHTML={{__html: stationTypeMessage}}/>
                </Text>

                {upload && <UploadPhoto stationName={stationName} upload={upload} />}

            </Flex>

    );
};

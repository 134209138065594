// https://chakra-ui.com/docs/styled-system/customize-theme#scaling-out-your-project
// For more information about the `theme` folder and its structure.
import { extendTheme } from "@chakra-ui/react";
import { Button } from "./components/Button";
import styles from "./styles";

const overrides = {
  // Foundational style overrides go here
  colors: {
    text: "#27374d",
    background: "#ffffff",
    accentBackground: "#2f2fa2",
    green100: "#648d6e",
    green75: "#406965",
    green55: "#73918e",
    green35: "#a6b9b7",
    green15: "#d0d7cd",
    green8: "#ebeded",
    peach100: "#efa68d",
    peach75: "#ffb68b",
    peach55: "#ffcaaa",
    peach35: "#ffddc9",
    peach15: "#fff0e8",
    peach8: "#fff7f3",
    backgroundGray: "#fafafa",
    backgroundRed: "#f9d4d4",
    textRed: "#e22828",
  },
  styles,
  components: {
    Button,
  },
  fonts: {
    heading: "Roboto-regular",
    body: "Roboto-regular"
  }
};

export default extendTheme(overrides);

import { Flex, Heading, Spinner, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BreakfastWarningModal } from "../components/BreakfastWarningModal";
import { StationInformation } from "../components/StationInformation";
import { ToEarlyWarningModal } from "../components/ToEarlyWarningModal";
import {SystemMessage} from "../components/SystemMessage";

let { REACT_APP_BACKEND_API } = process.env;

export const StationScreen = () => {
  const { group, instance } = useParams();
  const [isStationLoading, setIsStationLoading] = useState(true); // Boolean
  const [isLoading, setIsLoading] = useState(false); // Boolean
  const [station, setStation] = useState(null);
  const [sgroup, setSgroup] = useState(null);
  const [systemStatus, setSystemStatus] = useState(null);
  const [goingToNextStation, setGoingToNextStation] = useState(false); // Boolean
  const [skipWaitTime, setSkipWaitTime] = useState(false); // Boolean
  const {
    isOpen: isOpenBreakfast,
    onOpen: onOpenBreakfast,
    onClose: onCloseBreakfast,
  } = useDisclosure(); // Breakfast modal controls
  const {
    isOpen: isOpenToEarly,
    onOpen: onOpenToEarly,
    onClose: onCloseToEarly,
  } = useDisclosure(); // To Early modal controls

  useEffect(() => {
    // if the time since the new station started is less than two minutes, show "going to the next station"
    scheduleNextStation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station]);

  useEffect(() => {
    if (!isStationLoading) {
      fetchCurrentStationInfo(instance, group);
    }
    setIsStationLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStationLoading]);

  // TODO - implement breakfast warning - need information about the times / API
  // TODO - implement the "you've been on this station for less than 2 minutes" warning.

  const fetchNextStation = async (skipBreakfastCheck, skipToEarlyCheck) => {
    // Check Breakfast
    if (skipBreakfastCheck !== true) {
      console.debug(
        `App.js - checking breakfast before fetching the next station`
      );
      checkBreakfast();
      return;
    }
    // Check if it's been less than X minutes since this station
    // X = 240 seconds, aka 4 minutes x 60 seconds
    if (
      skipToEarlyCheck !== true &&
      Date.now() / 1000 < station.startTime + 240
    ) {
      console.debug(
        `App.js - asking the user to confirm the to early modal before fetching the next station`
      );
      onOpenToEarly();
      return;
    }

    try {
      console.debug(`Next station - fetching the next station information`);
      const result = await axios.get(
        `${REACT_APP_BACKEND_API}/api/fairground/stop-start-group/${group}` + (instance ? `/${instance}` : ''),
        {
          responseType: "json",
          cors: false,
          transformResponse: (data) => {
            return JSON.parse(data);
          },
        }
      );

      console.debug(`Next station - fetched information`, result);
      setSkipWaitTime(false);
      setSystemStatus(result.data.systemStatus);
      fetchCurrentStationInfo();

      scheduleNextStation();
    } catch (error) {
      console.debug(
        `An error has occurred while fetching next station information`
      );
      console.error(error);
    }
  };

  const fetchCurrentStationInfo = async () => {
    setIsLoading(true);
    try {

      const result = await axios.get(
        `${REACT_APP_BACKEND_API}/api/fairground/group-info/${group}` + (instance ? `/${instance}` : ''),
        {
          responseType: "json",
          cors: false,
        }
      );

      setStation(result.data.station);
      setSgroup(result.data.group);
      setSystemStatus(result.data.systemStatus);

      scheduleNextStation();
    } catch (error) {
      console.debug(
        `An error has occurred while fetching current station information`
      );
      console.error(error);
    }
    setIsLoading(false);
  };

  const checkBreakfast = () => {
    console.debug(`App.js - checking breakfast`, systemStatus);
    if (station && systemStatus === 5) {
      // Game open, lunch flag raised
      onOpenBreakfast();
    } else {
      fetchNextStation(true);
    }
  };

  const scheduleNextStation = () => {
    // if the time since the new station started is less than two minutes, show "going to the next station"
    if (
      station == null ||
      Math.floor(Date.now() / 1000) - station.startTime > 120
    ) {
      return;
    }
    if (skipWaitTime === true) {
      console.debug(`App.js - Timeout - skip the wait is TRUE`, skipWaitTime);
      return;
    }
    console.debug(
      `App.js - Timeout - setting goingToNextStation to TRUE  for ${
        (120 - (Math.floor(Date.now() / 1000) - station.startTime)) * 1000
      } milliseconds`
    );
    setGoingToNextStation(true);
    setTimeout(() => {
      console.debug(`App.js - Timeout - setting goingToNextStation to FALSE`);
      setGoingToNextStation(false);
      console.debug(
        `App.js - Timeout - fetching the current station information`
      );
      fetchCurrentStationInfo();
    }, (120 - (Math.floor(Date.now() / 1000) - station.startTime)) * 1000);
  };

  const skipToNextStation = () => {
    console.debug(`App.js - skipping the wait for the new station`);
    setSkipWaitTime(true);
    setStation({
      ...station,
      startTime: Math.floor(Date.now() / 1000 - 120),
    });
    setGoingToNextStation(false);
  };

  return (
    <>
      {isLoading === false && station === null ? (
        <Flex direction="column">
          <Heading>Loading the station information...</Heading>
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <StationInformation
            group={sgroup}
            station={station}
            goToNextStation={goingToNextStation}
            getNextStation={fetchNextStation}
            skipToNextStation={skipToNextStation}
            isLoading={isLoading}
            systemStatus={systemStatus}
          />
          <BreakfastWarningModal
            isOpen={isOpenBreakfast}
            onCancel={() => {
              console.debug(
                "App.js - getting new station canceled due to breakfast"
              );
              onCloseBreakfast();
            }}
            onSubmit={() => {
              onCloseBreakfast();
              fetchNextStation(true);
            }}
          />
          <ToEarlyWarningModal
            isOpen={isOpenToEarly}
            onCancel={() => {
              console.debug(
                "App.js - canceled getting new station due to being too early"
              );
              onCloseToEarly();
            }}
            onSubmit={() => {
              onCloseToEarly();
              fetchNextStation(true, true);
            }}
          />
        </>
      )}
    </>
  );
};

export const Button = {
  defaultProps: {
    variant: "secondary", // Default button color scheme
  },
  variants: {
    primary: {
      color: "#ffffff",
      background: "green100",
      height: "56px",
      width: "291px",
      borderRadius: "28px",
      _hover: {
        background: "green75",
      },
      _active: {
        background: "green55",
      },
    },
    secondary: {
      color: "#ffffff",
      background: "green55",
      height: "56px",
      width: "291px",
      borderRadius: "28px",
      _hover: {
        background: "green35",
      },
      _active: {
        background: "green15",
      },
    },
    cancel: {
      color: "#ffffff",
      background: "peach100",
      height: "56px",
      width: "291px",
      borderRadius: "28px",
      _hover: {
        background: "peach75",
      },
      _active: {
        background: "peach55",
      },
    },
  },
};
